const companyProfile = [
	{
		path: '/companyProfile',
		name: 'companyProfile',
		redirect: '/companyProfile/introduction',
		component: () => import('@/views/companyProfile/index.vue'),
		meta: {
			title: '771771威尼斯.Cm',
		},
		children: [
			{
				path: 'introduction',
				name: 'introduction',
				component: () =>
					import('@/views/companyProfile/introduction.vue'),
				meta: {
					title: '集团简介',
				},
			},
			{
				path: 'leader',
				name: 'leader',
				component: () => import('@/views/companyProfile/leader.vue'),
				meta: {
					title: '领导致辞',
				},
			},
			{
				path: 'philosophy',
				name: 'philosophy',
				component: () =>
					import('@/views/companyProfile//philosophy.vue'),
				meta: {
					title: '企业理念',
				},
			},
			{
				path: 'framework',
				name: 'framework',
				component: () => import('@/views/companyProfile/framework.vue'),
				meta: {
					title: '组织架构',
				},
			},
			{
				path: 'certificate',
				name: 'certificate',
				component: () =>
					import('@/views/companyProfile/certificate.vue'),
				meta: {
					title: '企业证书',
				},
			},
			{
				path: 'honer',
				name: 'honer',
				component: () => import('@/views/companyProfile/honer.vue'),
				meta: {
					title: '企业荣誉',
				},
			},
		],
	},
]
export default companyProfile
